// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

require("newlook/styles/styles")

require("newlook/cookieconsent.min")
require("newlook/mmenu.min")
require("chosen-js")
require("slick-carousel")

require("revslider/js/jquery.themepunch.tools.min")
require("revslider/js/jquery.themepunch.revolution.min")
require("revslider/js/extensions/revolution.extension.actions.min")
require("revslider/js/extensions/revolution.extension.carousel.min")
require("revslider/js/extensions/revolution.extension.kenburn.min")
require("revslider/js/extensions/revolution.extension.layeranimation.min")
require("revslider/js/extensions/revolution.extension.migration.min")
require("revslider/js/extensions/revolution.extension.navigation.min")
require("revslider/js/extensions/revolution.extension.parallax.min")
require("revslider/js/extensions/revolution.extension.slideanims.min")
require("revslider/js/extensions/revolution.extension.video.min")

require("bootstrap/dist/js/bootstrap")
require("rangeslider.js")
require("magnific-popup")
require("waypoints/src/waypoint")
require("newlook/counterup.min")
require("jquery-ui")
require("newlook/tooltips.min")
require("jquery.qrcode")
require("newlook/custom")
require("newlook/markerclusterer")
require("newlook/maps")
require("jquery-countdown")
require("hoverintent")
require("datedropper3")
require("newlook/consentpopup")
require("newlook/my-functions")
require("newlook/ga")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true)
// const imagePath = (name) => images(name, true)
