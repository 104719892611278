var BTL_RATE, FIRST_TIME_RATE, calculate_mortgage, calculate_rent_affordability, calculate_stamp_duty, change_rates, floatToCurrency;

FIRST_TIME_RATE = [0.0, 0.02, 0.05, 0.1, 0.12];

BTL_RATE = [0.03, 0.05, 0.08, 0.13, 0.15];

change_rates = function() {
  return $('input:radio[name="sd-type"]').change(function() {
    var i, index, len, rate_elements, rates, the_element, value;
    rate_elements = $('td.tier-rate');
    rates = $('#first-time-buyer').is(':checked') ? FIRST_TIME_RATE : BTL_RATE;
    for (index = i = 0, len = rates.length; i < len; index = ++i) {
      value = rates[index];
      the_element = $(rate_elements[index]);
      the_element.html((value * 100) + '%');
      the_element.attr("data-rate", value);
    }
    calculate_stamp_duty();
  });
};

calculate_stamp_duty = function() {
  var property_price, rate_elements, sum;
  property_price = parseInt($('#property-price').val());
  rate_elements = $('td.tier-rate');
  if (!isNaN(property_price)) {
    sum = 0;
    $('td.tier-price').each(function(index) {
      var max, min, rate;
      min = $(this).attr('data-min');
      max = $(this).attr('data-max');
      rate = $(rate_elements[index]).attr('data-rate');
      if (property_price <= min) {
        return;
      }
      if (max === 'unlimited' || property_price <= max) {
        return sum += (property_price - min) * rate;
      } else {
        return sum += (max - min) * rate;
      }
    });
    $('#sd-result').html('£' + floatToCurrency(sum));
    $('#sd-rate').html((sum * 100 / property_price).toFixed(2) + '%');
  }
};

calculate_mortgage = function() {
  var deposit, interest, monthly, monthly_rate, mortgage, pows, price, r_only_monthly, terms;
  price = parseFloat($('#price').val().replace(",", ""));
  deposit = parseFloat($('#deposit').val().replace(",", ""));
  interest = parseFloat($('#interest').val().replace(",", "")) / 100;
  terms = parseInt($('#terms').val());
  if (!isNaN(price && deposit && interest && terms)) {
    mortgage = price - deposit;
    $('#mortgage').val(floatToCurrency(mortgage));
    $('#price').val(floatToCurrency(price));
    $('#deposit').val(floatToCurrency(deposit));
    monthly_rate = interest / 12;
    terms = terms * 12;
    pows = Math.pow(1 + monthly_rate, terms);
    monthly = mortgage * monthly_rate * pows / (pows - 1);
    r_only_monthly = mortgage * monthly_rate;
    $('#monthly').val(floatToCurrency(monthly, 0));
    $('#r-interest').val(floatToCurrency(r_only_monthly, 0));
  }
};

calculate_rent_affordability = function(type) {
  var guarantor_income, income, monthly_rent, tenant_income;
  if (type === 'rent') {
    monthly_rent = parseFloat($('#mth-rent').val());
    tenant_income = (monthly_rent * 12) * 2.5;
    guarantor_income = (monthly_rent * 12) * 3;
    $('#monthly-rent').html(monthly_rent);
    $('#mth-rent-result-1').html('£' + floatToCurrency(tenant_income, 0));
    $('#mth-rent-result-2').html('£' + floatToCurrency(guarantor_income, 0));
  } else {
    income = parseFloat($('#income').val());
    monthly_rent = (income / 2.5) / 12;
    guarantor_income = (monthly_rent * 12) * 3;
    $('#annual-income').html(floatToCurrency(income, 0));
    $('#income-result-1').html('£' + floatToCurrency(monthly_rent, 0));
    $('#income-result-2').html('£' + floatToCurrency(guarantor_income, 0));
  }
};

floatToCurrency = function(value, decimal) {
  if (decimal == null) {
    decimal = 2;
  }
  return value.toFixed(decimal).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

window.init_stampDutyCalculator = function() {
  change_rates();
  $('.update-stamp-duty').keyup(function() {
    return calculate_stamp_duty();
  });
};

window.init_mortgageCalculator = function() {
  calculate_mortgage();
  $('.update-mortgage').change(function() {
    return calculate_mortgage();
  });
};

window.init_rentAffordabilityCalculator = function() {
  calculate_rent_affordability('rent');
  calculate_rent_affordability('income');
  $('#mth-rent').keyup(function() {
    return calculate_rent_affordability('rent');
  });
  $('#income').keyup(function() {
    return calculate_rent_affordability('income');
  });
};
